import { Component, HostListener, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAddComponent } from './home/home/dialogs/dialog-add.component';


import Swal from 'sweetalert2'

import { environment } from '../environments/environment';

const urlApi: string = `${environment.apiEndpoint}`;

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

import { Observable } from 'rxjs';


import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';


import { CustomerResourcesService } from './home/customer-resources.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  facebook = faFacebookF;
  whatsapp = faWhatsapp;
  envelope = faEnvelope;

  
  title = 'landingpage';

  posts_categories: Array<any> = [];
  


  constructor(@Inject(PLATFORM_ID) private platformId: Object, public dialog: MatDialog, private breakpointObserver: BreakpointObserver, private router: Router, public resources: CustomerResourcesService) {

   
    
  }    


 


  getPostsCategories()
  {

    this.resources.getPostsCategories().subscribe(
      (data) => { // Success
        console.log(data);
        



        this.posts_categories = data;
        

       
      },
      (error) =>{
        console.error(error);
      }
    );

  }



  ngOnInit()
  {


    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });


    //this.getPostsCategories();


  }

  
}
