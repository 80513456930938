<ng-progress #progressBar [color]="'#009688'" [spinner]="false"></ng-progress>
<header class="header d-print-none header-container" role="banner">
      
      <mat-toolbar class="mat-toolbar-white">
        
         
         
              
              <button fxHide="false" class="d-block d-lg-none" *ngIf="!SideNav.opened"
                type="button"
                mat-icon-button
                (click)="SideNav.toggle()"
                title="Open sidenav">
                <mat-icon>menu</mat-icon>
              </button>

             
              <button mat-icon-button type="button" (click)="SideNav.toggle()" *ngIf="SideNav.opened" title="Open sidenav"  fxHide="false" class="d-block d-lg-none">
                <mat-icon>close</mat-icon>
              </button>
              
              <a class="no-link-style text-uppercase blueColor toolbar-link d-none d-md-block" mat-button [routerLink]="['/']">
                <img src="../assets/img/logo.png" class="img img-fluid brand-image-toolbar" alt="The Integrative ED Clinic - Regenerative Therapy for Erectile Dysfunction (ED)" title="The Integrative ED Clinic - Logo">
              </a>

              <a class="no-link-style text-uppercase blueColor toolbar-link d-block d-md-none" mat-button [routerLink]="['/']">
                <img src="../assets/img/logo-mobile.png" class="img img-fluid brand-image-toolbar-mobile" alt="The Integrative ED Clinic - Regenerative Therapy for Erectile Dysfunction (ED)" title="The Integrative ED Clinic - Logo">
              </a>

             
              <span class="example-fill-remaining-space"></span>

              <!-- This fills the remaining space of the current row -->


          
        

             



              <span class="fill-remaining-space"></span>

              <div class="d-none d-lg-block">

                  <a class="no-link-style text-uppercase blueColor toolbar-link" mat-button [routerLink]="['/']">HOME</a>
                
                  <a class="no-link-style text-uppercase blueColor toolbar-link" mat-button [routerLink]="['/articles']" routerLinkActive="active-route">ARTICLES</a>

                  <!--<button class="no-link-style text-uppercase blueColor toolbar-link" mat-button [matMenuTriggerFor]="menu">ARTICLES</button>
                  <mat-menu #menu="matMenu">
                   

                    <a mat-menu-item class="no-link-style text-uppercase blueColor toolbar-link" [routerLink]="['/articles']" routerLinkActive="active-route">ALL ARTICLES</a>
                    
                    <a mat-menu-item class="no-link-style text-uppercase blueColor toolbar-link" *ngFor="let item of posts_categories" [routerLink]="['/articles', item.slug]" [title]="item.name" routerLinkActive="active-route">{{ item.name }}</a>


                  </mat-menu>-->



                  <a class="no-link-style text-uppercase blueColor toolbar-link" mat-button [routerLink]="['/recipes']" routerLinkActive="active-route">RECIPES</a>

                  <a class="no-link-style text-uppercase blueColor toolbar-link" mat-button [routerLink]="['/articles/biohacking']" routerLinkActive="active-route">BIOHACKING</a>
                 
                  <a class="no-link-style text-uppercase blueColor toolbar-link" mat-button [routerLink]="['/about']" routerLinkActive="active-route">ABOUT</a>

                  <!--<a class="no-link-style text-uppercase blueColor toolbar-link" mat-button [routerLink]="['/contact']" routerLinkActive="active-route">contact</a>-->

                  <a class="no-link-style text-uppercase blueColor toolbar-link" mat-button href="https://shop.drramon.com/" routerLinkActive="active-route">SHOP</a>


                  

                 
                  




                  <!--<a mat-icon-button class="no-link-style text-uppercase" href="#" target="_blank">
                    
                    <fa-icon [icon]="facebook"></fa-icon>
                  </a>

                  <a mat-icon-button class="no-link-style text-uppercase" href="https://api.whatsapp.com/send?phone=+16197485552" target="_blank">
                    <fa-icon [icon]="whatsapp"></fa-icon>
                  </a>

                  <a mat-icon-button class="no-link-style text-uppercase" href="mailto:contact@benaturalmed.com" target="_blank">
                    <fa-icon [icon]="envelope"></fa-icon>
                  </a>-->


              </div>
              

          
        
      </mat-toolbar>
    </header>


    

    


<mat-sidenav-container class="all-wrap">
  <mat-sidenav #SideNav [class.mat-elevation-z4]="true" mode="over"><!-- over, push, side -->
    
   
    

    <mat-list>
      
      <a class="link-hover" mat-list-item matLine [routerLink]="['/']" (click)="SideNav.close();">HOME</a>

      <a class="link-hover" mat-list-item matLine [routerLink]="['/articles']" (click)="SideNav.close();">ARTICLES</a>

      <a class="link-hover" mat-list-item matLine [routerLink]="['/recipes']" (click)="SideNav.close();">RECIPES</a>

      <a class="link-hover" mat-list-item matLine [routerLink]="['/articles/nutrition-and-supplements']" (click)="SideNav.close();">BIOHACKING</a>

      <a class="link-hover" mat-list-item matLine [routerLink]="['/about']" (click)="SideNav.close();">ABOUT</a>

      
      <!--<a class="link-hover" mat-list-item matLine [routerLink]="['/contact']" (click)="SideNav.close();">CONTACT</a>-->


      <a class="link-hover" mat-list-item matLine href="https://shop.drramon.com/" (click)="SideNav.close();">SHOP</a>

      

      

    </mat-list>
  </mat-sidenav>
 
  <div class="page-wrap">
    
    <main class="content">
     


    
      <div class="content-section header_margin" style="background:white;">
        
        <router-outlet></router-outlet>

      </div>
        



    </main>
    <app-footer></app-footer>
  </div>
</mat-sidenav-container>