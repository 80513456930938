import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {
  }
	
  transform(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}
