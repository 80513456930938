import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';

const routes: Routes = [
  
  { path: '', loadChildren: () => import('../app/home/home.module').then(m => m.HomeModule) },

  { path: 'articles/:id_category', loadChildren: () => import('../app/articles/articles.module').then(m => m.ArticlesModule) },

  { path: 'articles', loadChildren: () => import('../app/articles/articles.module').then(m => m.ArticlesModule) },

  { path: 'article/:id_article', loadChildren: () => import('../app/article/article.module').then(m => m.ArticleModule) },

  { path: 'recipe/:id_recipe', loadChildren: () => import('../app/recipe/recipe.module').then(m => m.RecipeModule) },

  { path: 'about', loadChildren: () => import('../app/about/about.module').then(m => m.AboutModule) },

  { path: 'recipes', loadChildren: () => import('../app/recipes/recipes.module').then(m => m.RecipesModule) },

  { path: 'recipes/:id_category', loadChildren: () => import('../app/recipes/recipes.module').then(m => m.RecipesModule) },

  //{ path: 'contact', loadChildren: () => import('../app/contact/contact.module').then(m => m.ContactModule) },

  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
}), NgProgressModule,
    NgProgressRouterModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
