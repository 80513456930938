import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { SanitizeHtmlPipe } from './sanitize-html.pipe';


@NgModule({
  declarations: [
    SanitizeHtmlPipe
  ],
  imports: [FormsModule, ReactiveFormsModule, MatCardModule, MatToolbarModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatIconModule, MatSidenavModule, MatDialogModule, MatSelectModule, MatTooltipModule, MatRadioModule, MatStepperModule,FontAwesomeModule,MatExpansionModule,MatRadioModule,MatListModule,MatAutocompleteModule,MatMenuModule],
  exports: [FormsModule, ReactiveFormsModule, MatCardModule, MatToolbarModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatIconModule, MatSidenavModule, MatDialogModule, MatSelectModule, MatTooltipModule, MatRadioModule, MatStepperModule,FontAwesomeModule,MatExpansionModule,MatRadioModule,MatListModule,MatAutocompleteModule,SanitizeHtmlPipe,MatMenuModule]
})
export class SharedModule { }
