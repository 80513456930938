import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

const urlApi: string = `${environment.apiEndpoint}`;

const urlApiWc: string = `${environment.apiEndpointWc}`;

const urlApiNode: string = `${environment.apiEndpointNode}/subscribers`;;


@Injectable({
  providedIn: 'root'
})
export class CustomerResourcesService {

  constructor(private http: HttpClient) { }



  addCustomer(Customer:any): Observable<any>{


      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
      };

      let urlApiEndPoint = `${urlApi}/add-from-public`;

      return this.http.post(urlApiEndPoint, Customer , httpOptions);
  
  }



  getPostsSlidersList(): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };

    
   
    const requestUrl =
        `${urlApi}/posts?_embed&_fields=slug,author,id,excerpt,title,link,date,_links&per_page=6&offset=0`;

    return this.http.get(requestUrl, httpOptions);

  }

  
  getPostsList(): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };

    
   
    const requestUrl =
        `${urlApi}/posts?_embed&_fields=slug,author,id,excerpt,title,link,date,_links&per_page=6&offset=0`;

    return this.http.get(requestUrl, httpOptions);

  }


  getAllPostsList(pagination?, pageSize?, search?): Observable<any> {

    let _pageSize = pageSize;
    let pageIndex;
    
    if(pagination)
    {

      _pageSize = pagination.pageSize;
      pageIndex = pagination.pageIndex;

    }


    if(!pageIndex) {

      pageIndex = 0;

    }
    else
    {

      pageIndex = pageIndex*_pageSize;

    }

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      observe: 'response' as 'response'
    };

    let requestUrl;
    
    if(search)
    {
     requestUrl =
        `${urlApi}/posts?_embed&search=${search}&_fields=slug,author,id,excerpt,title,link,date,_links&per_page=${_pageSize}&offset=${pageIndex}`;
    }
    else
    {
     requestUrl =
        `${urlApi}/posts?_embed&_fields=slug,author,id,excerpt,title,link,date,_links&per_page=${_pageSize}&offset=${pageIndex}`;
    }

    return this.http.get(requestUrl, httpOptions);

  }


  getAllPostsListBySearch(search?): Observable<any> {

   

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      observe: 'response' as 'response'
    };

    
   
    const requestUrl =
        `${urlApi}/search?search=${search}&type=post&subtype=post`;

    return this.http.get(requestUrl, httpOptions);

  }


  getPostsCategories(): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };

    
   
    const requestUrl =
        `${urlApi}/categories`;

    return this.http.get(requestUrl, httpOptions);

  }


  getArticlesByCategoryId(id, pagination?, pageSize?, search?): Observable<any> {



    let _pageSize = pageSize;
    let pageIndex;
    
    if(pagination)
    {

      _pageSize = pagination.pageSize;
      pageIndex = pagination.pageIndex;

    }


    if(!pageIndex) {

      pageIndex = 0;

    }
    else
    {

      pageIndex = pageIndex*_pageSize;

    }


    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      observe: 'response' as 'response'
    };

    let requestUrl;

    if(search)
    {
      requestUrl =
        `${urlApi}/posts?_embed&search=${search}&categories=${id}&_fields=author,slug,id,excerpt,title,link,date,_links&per_page=${_pageSize}&offset=${pageIndex}`;
    }
    else {

      requestUrl =
        `${urlApi}/posts?_embed&categories=${id}&_fields=author,slug,id,excerpt,title,link,date,_links&per_page=${_pageSize}&offset=${pageIndex}`;
    }

    return this.http.get(requestUrl, httpOptions);

  }



  getArticleById(id): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };

    
   
    const requestUrl =
        `${urlApi}/posts/?_embed&slug=${id}&_fields=author,slug,id,excerpt,content,title,link,date,_links`;

    return this.http.get(requestUrl, httpOptions);

  }



  getSlugArticleById(id): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };

    
   
    const requestUrl =
        `${urlApi}/posts/${id}/?_fields=slug,id`;

    return this.http.get(requestUrl, httpOptions);

  }



  getCategoryBySlug(slug): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };

    
   
    const requestUrl =
        `${urlApi}/categories?slug=${slug}`;

    return this.http.get(requestUrl, httpOptions);

  }



  getAutocomplete(filter?, pagination?, pageSize?, id?) {

    let _pageSize = pageSize;
    let pageIndex;
    
    if(pagination)
    {

      _pageSize = pagination.pageSize;
      pageIndex = pagination.pageIndex;

    }


    if(!pageIndex) {

      pageIndex = 0;

    }
    else
    {

      pageIndex = pageIndex*_pageSize;

    }


      
    //const requestUrl = `${urlApi}/search?search=${filter}&type=post&subtype=post_tag`;

    //const requestUrl = `${urlApi}/search?_embed&search=${filter}&type=post&subtype=any&context=embed&_fields=author,slug,id,excerpt,content,title,link,date,_links`;

    let requestUrl;

    if(id)
    {
      requestUrl = `${urlApi}/posts/?search&_embed&search=${filter}&categories=${id}&type=post&subtype=any&context=embed&_fields=author,slug,id,excerpt,content,title,link,date,_links&per_page=${_pageSize}&offset=${pageIndex}`;
    }
    else
    {
      requestUrl = `${urlApi}/posts/?search&_embed&search=${filter}&type=post&subtype=any&context=embed&_fields=author,slug,id,excerpt,content,title,link,date,_links&per_page=${_pageSize}&offset=${pageIndex}`;
    }





    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      observe: 'response' as 'response'
    };

    
  

    return this.http.get(requestUrl, httpOptions);
      
  }


  getProducts(): Observable<string[]> {

    let consumerKey = "ck_b85e44bf6eee11dfb780bfa8e1b51db0876e58a2";
    let consumerSecret = "cs_0a220a03292e18b7e2c099e8bab45eda19034ad2";
        
    const requestUrl = `${urlApiWc}/products?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&per_page=10`;


    return this.http.get<string[]>(requestUrl);
      
  }


  addNewSubscriber(form:any): Observable<any> {


      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'})
      };


      let api = `${urlApiNode}/add-from-web`;
        
      return this.http.post(api, form, httpOptions);
  
  }

  

}
