import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';

import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookF, faInstagram, faLinkedin, faWhatsapp, faFacebookMessenger, faTelegram } from '@fortawesome/free-brands-svg-icons';

import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

import { CustomerResourcesService } from '../home/customer-resources.service';

import Swal from 'sweetalert2'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  now: string;

  faTwitter = faTwitter;
  faFacebookF = faFacebookF;
  faEnvelope = faEnvelope;
  faInstagram = faInstagram;
  faPhone = faPhoneAlt;
  faLinkedin = faLinkedin;
  faWhatsapp = faWhatsapp;
  faFacebookMessenger = faFacebookMessenger;
  faTelegram = faTelegram;

  addEmailForm: FormGroup;
  sendData: boolean = false;
  
  constructor(private fb: FormBuilder, public resources: CustomerResourcesService) { 

  	this.now = moment().format('YYYY');

    this.addEmailForm = fb.group({  
     
      'email': [null, [Validators.required, Validators.email]],
      
    });

  }


  addEmailFormFunction(form) {

    this.sendData = true;

      //this.addEmailForm.reset();
    /*setTimeout(function() {

        this.sendData = false;

        Swal.fire(
          'Thank you!',
          'We will be contacting you shortly!',
          'success'
        )

    },200);*/

    this.resources.addNewSubscriber(form).subscribe(
      (data) => { // Success
        
        this.addEmailForm.reset();
        this.sendData = false;

        Swal.fire(
          'Thank you!',
          'For subscribing!',
          'success'
        )

      },
      (error) =>{
        
        if(error.error=="duplicate")
        {
          //alert(`The email: ${form.email} is already subscribed.`);

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `The email: ${form.email} is already subscribed!`,
          })

        }
        else
        {
          
          alert('Error');

        }

        this.sendData = false;
      }
    )

  

  }


 




  ngOnInit() {
  }

}
