<footer>
  

  <section id="pagetitle-home" class="container-fluid section line-section">
  

    <div class="container">

     
      
      <div class="row">
        
        <div class="col-md-10 offset-md-1">
          
          <h2 class="text-center" style="font-weight: 500;font-size: 28px;"><!--Subscribe to the FREE #1 Natural Health Newsletter-->
            
            Subscribe to the best source for regenerative and 
          </h2>

          <h2 class="text-center" style="font-weight: 500;font-size: 28px;"><!--Subscribe to the FREE #1 Natural Health Newsletter-->
            
            antiaging medical news and info...
            
          </h2>

        </div>

        <div class="col-md-6 offset-md-3">

          <form [formGroup]="addEmailForm" (ngSubmit)="addEmailFormFunction(addEmailForm.value)">
            
            <mat-form-field appearance="outline">

              <mat-label>Email</mat-label>
            
           
              <input matInput formControlName="email" type="email" required autocomplete="off">

            
              
              <mat-error *ngIf="addEmailForm.get('email').hasError('required')">
                Email is required
              </mat-error>

              <mat-error *ngIf="addEmailForm.get('email').hasError('email')">
                Must be a valid email
              </mat-error>


            </mat-form-field>

            <button mat-flat-button color="primary" [disabled]="!addEmailForm.valid || sendData==true" class="full-width" type="submit">Subscribe</button>

          </form>

        </div>

       

  </div>

  </div>
</section>


</footer>

<footer class="footer footer01 container-fluid" id="contact">
	<div class="container">
		
		<div class="row">
			
			<div class="col-lg-12 col-md-12">
		
					
    		


            <div class="footer-social-networks-inline">
                
                <ul class="text-center list-unstyled">
                  
                 
                  <li>
                    <a matTooltip="Facebook" href="https://www.facebook.com/integrativelongevity" target="_blank"><fa-icon [icon]="faFacebookF"></fa-icon></a>
                  </li>
                  <!--<li>
                    <a matTooltip="Linkedin" href="https://www.linkedin.com/in/ramon-de-la-puerta-md-13437a30/" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
                  </li>-->
                  <li>
                    <a matTooltip="Instagram" href="https://www.instagram.com/dr_ramondlp/" target="_blank"><fa-icon [icon]="faInstagram"></fa-icon></a>
                  </li>
                  <!--
                  <li>
                    <a matTooltip="Whatsapp" href="#"><fa-icon [icon]="faWhatsapp"></fa-icon></a>
                  </li>

                  <li>
                    <a matTooltip="Telegram" href="#"><fa-icon [icon]="faTelegram"></fa-icon></a>
                  </li>-->

                  <!--<li>
                    <a matTooltip="Email" href="mailto:drramon@zignagenix.com"><fa-icon [icon]="faEnvelope"></fa-icon></a>
                  </li>-->

<!--
                  <li>
                    <a matTooltip="Facebook Messenger" href="#"><fa-icon [icon]="faFacebookMessenger"></fa-icon></a>
                  </li>-->
          
                </ul>

            </div>

			
			</div>



            <div class="col-lg-12 margin-top mobile-margin-top">
                
                
                <p>
                    


                    Disclaimer: The entire contents of this website are based upon the opinions of Dr. Ramon , unless otherwise noted. Individual articles are based upon the opinions of the respective author, who retains copyright as marked. The information on this website is not intended to replace a one-on-one relationship with a qualified health care professional and is not intended as medical advice. It is intended as a sharing of knowledge and information from the research and experience of Dr. Ramon and his community. Dr. Ramon encourages you to make your own health care decisions based upon your research and in partnership with a qualified health care professional. If you are pregnant, nursing, taking medication, or have a medical condition, consult your health care professional before using products based on this content.



                </p>


                <p>

                    If you want to use an article on your site please click here. This content may be copied in full, with copyright, contact, creation and information intact, without specific permission, when used only in a not-for-profit format. If any other use is desired, permission in writing from Dr. Ramon is required.
                    
                </p>



            </div>


		</div>


    </div>

</footer>



<footer class="footer footer02 container-fluid">

    <div class="container">

	

		<div class="row">
			
			<div class="col-md-12">
				
				<div class="copyright-notice text-right">
					Copyright {{ now }} <a class="text-decoration-underline" href="#" style="color:#ffffff !important;">drramon.com</a> | All Rights Reserved 
				</div>

			</div>

		</div>
        

	</div>
</footer>